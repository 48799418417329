/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { shallowEqual, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Footer from './Footer';
import './layout.css';
import Header from './Header';
import TruckIco from '../images/assets/truck003.png';
import MessageIco from '../images/assets/message001.png';
import FileIco from '../images/assets/file001.png';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const role = useSelector((state: any) => state.role.role, shallowEqual);
  const loading = useSelector(
    (state: any) => state.loading.loading,
    shallowEqual,
  );
  const checkBadge = useSelector(
    (state: any) => state.checkBadge.checkBadges,
    shallowEqual,
  );
  return (
    <div className="customer-container">
      <LoadingOverlay active={loading} spinner text="Loading...">
        <Header role={role} />
        <main>
          <div className="wrapper-main">
            <div className="sidebar">
              <ul>
                <li>
                  <Link
                    activeClassName="active"
                    to={
                    role === 'customer'
                      ? `/${role}/delivery_request/post`
                      : `/${role}/delivery_request/`
                  }
                  >
                    <img
                      className="badge-img"
                      style={{ width: '32px' }}
                      src={TruckIco}
                      alt="truck icon"
                    />
                    {' '}
                    見積依頼投稿
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    className="flex-container"
                    to={`/${role}/delivery_request/`}
                  >
                    <img
                      className="badge-img"
                      style={{ width: '32x' }}
                      src={FileIco}
                      alt="file icon"
                    />
                    {' '}
                    見積依頼履歴
                    {checkBadge?.new_question
                    && checkBadge.new_question === true && (
                      <span className="badge badge-circle badge-pill badge-outline-danger">
                        {checkBadge?.total_question}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    className="flex-container"
                    to={`/${role}/delivery_request_temporarily`}
                  >
                    <img
                      className="badge-img"
                      style={{ width: '32x' }}
                      src={FileIco}
                      alt="file icon"
                    />
                    {' '}
                    下書き見積依頼
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex-container"
                    activeClassName="active"
                    to={
                    role === 'customer'
                      ? `/${role}/message/`
                      : `/${role}/delivery_request/suggestion`
                  }
                  >
                    <img
                      className="badge-img"
                      style={{ width: '32px' }}
                      src={MessageIco}
                      alt="message icon"
                    />
                    {' '}
                    提案メッセージ
                    {checkBadge?.new_message
                    && checkBadge.new_message === true && (
                      <span className="text-badge" style={{ color: 'red', paddingLeft: 30 }}>
                        新規提案あり
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="content-main">{children}</div>
          </div>
        </main>
        <Footer role={role} />
      </LoadingOverlay>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
