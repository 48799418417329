import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';

const ModalConfirm = ({
  handleCloseModal, text, statusModal, handleSubmitModal, labelSubmit, labelCancel,
}) => (
  <>
    <Modal isOpen={statusModal}>
      {/* <ModalHeader></ModalHeader> */}
      <ModalBody>
        {text}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmitModal}>{labelSubmit}</Button>
        <Button color="primary" onClick={handleCloseModal}>{labelCancel}</Button>
      </ModalFooter>
    </Modal>
  </>
);

export default ModalConfirm;
