import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';

const ModalCommon = ({ handleCloseModal, text, statusModal }) => (
  <>
    <Modal isOpen={statusModal}>
      {/* <ModalHeader></ModalHeader> */}
      <ModalBody>
        {text}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleCloseModal}>閉じる</Button>
      </ModalFooter>
    </Modal>
  </>
);

export default ModalCommon;
